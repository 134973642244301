<script setup lang="ts">
const { formattedTimeAgo } = useTimeAgo()
type TProps = {
	cardHorizontal?: boolean
	name?: string
	description?: string
	author?: object
	image?: string
	categories?: string[]
	subscribers?: number
	newsletterStats?: object
	updatedAt?: string
	slug?: string
	loading?: boolean
	isNew?: boolean
	verified?: boolean
	wantsCollaboration?: boolean
};
const props = withDefaults(defineProps<TProps>(), {
	isNew: false,
	verified: false,
	wantsCollaboration: false,
	cardHorizontal: true,
})
</script>

<template>
	<div v-if="cardHorizontal">
		<div>
			<NuxtLink :to="`newsletters/${slug}`">
				<div class="w-full mb-6 rounded-xl shadow-xl shadow-primary-100 ring-4 border-4 ring-transparent border-transparent overflow-hidden relative"
					:class="{ '!border-secondary-500': isNew, '!ring-primary-500': wantsCollaboration }">
					<div v-if="wantsCollaboration"
						class="bg-primary-500 p-0.5 px-1.5 absolute top-0 right-0 border rounded-tr rounded-bl-xl text-xs text-secondary-600 font-bold flex items-center justify-center">
						<Icon name="carbon:collaborate" class="h-6 w-6" />
						<span>Busco colaboración</span>
					</div>
					<div class="grid grid-cols-4">
						<div
							class="col-span-1 max-w-[350px] sm:max-w-[350px] min-h-[280px] max-h-[300px] relative">
							<img class="aspect-[16/9] h-full object-cover sm:aspect-[2/1] lg:aspect-[3/2] rounded-lg"
								:src="image" :alt="name" />

							<div v-if="isNew"
								class="bg-secondary-500 p-0.5 px-1.5 absolute top-0 left-0 border rounded-tl rounded-br-xl text-xs text-primary-600 font-bold flex items-center justify-center">
								<Icon name="ic:round-stars" class="h-6 w-6" />
								<span>Nuevo</span>
							</div>
						</div>
						<div class="col-span-3">
							<div
								class="h-[250px] bg-white rounded-lg  p-4 flex flex-col justify-between leading-normal ">
								<div class="mb-8">
									<div class="pb-2">
										<span v-for="(category, index) in categories"
											:key="index"
											class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-primary-600 mr-2 mb-2">
											{{ category.name }}
										</span>
									</div>
									<div>
										<p
											class="text-xl text-primary pb-3 flex items-baseline justify-start gap-x-2">
											<span>{{ name }}</span>
											<span v-if="verified"
												class="bg-primary-500 rounded-full inline-flex items-center justify-center gap-x-2 text-secondary-600 px-2 py-0.5">
												<Icon name="ic:sharp-verified"
													class="h-4 w-4" />
												<span class="text-xs">Verificado</span>
											</span>
										</p>
									</div>
									<div
										class="text-sm mb-8 text-gray-500 flex items-center line-clamp-1">
										{{ description }}
									</div>

									<div class="text-sm mb-2 text-gray-500 flex items-center">
										<span :text="`${subscribers} Suscriptores`">
											<Icon name="mingcute:group-fill"
												class="h-5 w-5 text-gray-500 mr-2" />
											<span>{{ subscribers }}</span>
											<span class="ml-2">Suscriptores</span>
										</span>
									</div>
									<div class="text-sm mb-2 text-gray-500 flex items-center">
										<span
											:text="`ultima actualización ${formattedTimeAgo(updatedAt)}`">
											<Icon name="material-symbols:alarm-on-outline"
												class="h-5 w-5 text-gray-500 mr-2" />
											<span class="mr-0.5">última actualización</span>
											<span>{{ formattedTimeAgo(updatedAt) }}</span>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</NuxtLink>
		</div>
	</div>
	<div v-else>
		<div>
			<NuxtLink :to="`newsletters/${slug}`">
				<div class="sm:max-w-sm rounded-lg overflow-hidden shadow-md shadow-primary-300 bg-white relative border-4 border-transparent ring-4 ring-transparent"
					:class="{ '!border-secondary-500': isNew, '!ring-primary-500': wantsCollaboration }">
					<img class="aspect-[16/9] min-h-[100px] w-full bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
						:src="image" :alt="name" />
					<div v-if="wantsCollaboration"
						class="bg-primary-500 p-0.5 px-1.5 absolute top-0 right-0 rounded-tr rounded-bl-xl text-xs text-secondary-600 font-bold flex items-center justify-center">
						<Icon name="carbon:collaborate" class="h-6 w-6" />
						<span>Busco colaboración</span>
					</div>
					<div v-if="isNew"
						class="bg-secondary-500 p-0.5 px-1.5 absolute top-0 left-0 border rounded-tl rounded-br-xl text-xs text-primary-600 font-bold flex items-center justify-center">
						<Icon name="ic:round-stars" class="h-6 w-6" />
						<span>Nuevo</span>
					</div>
					<div
						class="my-0 py-2 px-2 flex justify-start items-center overflow-x-auto border-y border-slate-100 gap-x-2 h-10">
						<span v-for="(category, index) in categories" :key="index"
							class="inline-block bg-primary-500 rounded-full px-1.5 py-0.5 text-xs font-semibold text-secondary-600">
							{{ category.name }}
						</span>
					</div>
					<div class="p-2">
						<p
							class="text-lg uppercase font-medium text-gray-700 line-clamp-2 mb-1 h-14 tracking-wide text-center">
							{{ name }}
							<span v-if="verified"
								class="bg-primary-500 rounded-full inline-flex items-center justify-center gap-x-2 text-secondary-600 p-0.5">
								<Icon name="ic:sharp-verified" class="h-4 w-4" />
							</span>
						</p>
						<div class="py-2 flex items-center justify-center w-full">
							<div
								class="text-sm text-gray-500 flex items-center gap-x-3 flex-1">
								<span :text="`${subscribers} Suscriptores`">
									<Icon name="mingcute:group-fill"
										class="h-5 w-5 text-gray-500 mr-2" />
									<span>{{ subscribers }}</span>
								</span>
							</div>
							<div class="text-sm text-gray-500 flex items-center">
								<span
									:text="`ultima actualización ${formattedTimeAgo(updatedAt)}`">
									<span>{{ formattedTimeAgo(updatedAt) }}</span>
									<Icon name="material-symbols:alarm-on-outline"
										class="h-5 w-5 text-gray-500 mr-2" />
								</span>
							</div>
						</div>
					</div>
				</div>
			</NuxtLink>
	</div>
</div></template>

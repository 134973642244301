import { useTimeAgo as timeAgo, formatTimeAgo } from '@vueuse/core'
import type { UseTimeAgoMessages, UnitNames } from '@vueuse/core'
interface ITimeAgo {
	rounding: 'ceil' | 'floor' | 'round'
	units: UnitNames
	messages: UseTimeAgoMessages<UnitNames>
}

export const useTimeAgo = () => {

	const options = {
		rounding: 'floor', // ceil | floor | round
		units: [
			{ max: 6e4, value: 1e3, name: "second" },
			{ max: 276e4, value: 6e4, name: "minute" },
			{ max: 72e6, value: 36e5, name: "hour" },
			{ max: 5184e5, value: 864e5, name: "day" },
			{ max: 24192e5, value: 6048e5, name: "week" },
			{ max: 28512e6, value: 2592e6, name: "month" },
			{ max: Number.POSITIVE_INFINITY, value: 31536e6, name: "year" }
		],
		messages: {
			justNow: "justo ahora",
			past: (n) => n.match(/\d/) ? `hace ${n}` : n,
			future: (n) => n.match(/\d/) ? `en ${n}` : n,
			month: (n, past) => n === 1 ? past ? "mes pasado" : "próximo mes" : `${n} mes${n > 1 ? "es" : ""}`,
			year: (n, past) => n === 1 ? past ? "año pasado" : "próximo año" : `${n} year${n > 1 ? "s" : ""}`,
			day: (n, past) => n === 1 ? past ? "ayer" : "mañana" : `${n} dia${n > 1 ? "s" : ""}`,
			week: (n, past) => n === 1 ? past ? "ultima semana" : "próxima semana" : `${n} semana${n > 1 ? "s" : ""}`,
			hour: (n) => `${n} hora${n > 1 ? "s" : ""}`,
			minute: (n) => `${n} minuto${n > 1 ? "s" : ""}`,
			second: (n) => `${n} segund${n > 1 ? "s" : ""}`,
			invalid: ""
		}
	}

	const formattedTimeAgo = (date: any) => {
		return timeAgo(date, options).value
	}

	return {
		formattedTimeAgo
	}
}
